@import 'style.module.scss';

.jitsi {
  height: 100%;
  overflow-y:hidden;

  iframe {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: none;
    padding: 0;
    margin: 0;
    display: block;
    min-height: 540px;
  }
}

.nav {
  display: flex;
  height: 120px;
  min-width: 765px;
  background-color: white;
  padding-left: 60px;
  padding-right: 60px;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 290px;
  height: 95px;
}


.bgImage {
  height: calc(100vh - 120px);
  min-width: 765px;
  //background: palevioletred;
}

.container {
  position: relative;
  min-height: 730px;
  overflow: hidden;
}

.infoItems {
  position: absolute;
  top: 60px;
  left: 0;
  //height: 597px;
  width: 732px;

  padding-bottom: 20px;

  background: white;

  &__welcome {
    background-color: white;
    padding: 20px 24px 20px 60px;

    border-bottom: 1px solid #CCCCCC;

    h1 {
      margin-top: 0;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      color: #004577;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 0;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: #000000;
      overflow-wrap: anywhere;
    }
  }

  &__selectors-grid {
    display: grid;
    padding: 20px 24px 20px 60px;
    grid-template-columns: 310px 1fr;
    grid-auto-rows: minmax(60px, auto);
    column-gap: 28px;
    row-gap: 20px;

    border-bottom: 1px solid #CCCCCC;

    .item {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }

  }

  &__buttons-panel {
    padding: 20px 68px 20px 60px;
    border-bottom: 1px solid #CCCCCC;
    display: flex;
    justify-content: space-between;

    .item {
      width: 307px;

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
    }

    .buttons-wrapper {
      display: flex;
      width: 180px;
      row-gap: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;




      .not-ready {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;

        background: #FFFFFF;
        border: 2px solid #004577;
        border-radius: 100px;
        text-transform: none;

        height: 40px;


        color: #004577;

        &:hover {
          color: rgba(0, 69, 119, 0.7);
          border: 2px solid rgba(0, 69, 119, 0.7);
        }

        &:focus {
          box-shadow: 0px 0px 4px 2px #8EA4C5;
        }
      }

      .ready {

        padding-top: 10px;
        padding-bottom: 10px;

        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;

        color: white;

        height: 40px;


        background: #004577;
        border: 2px solid #004577;
        border-radius: 100px;

        &:hover {
          background-color: #4D7DA0;
          border-color: #4D7DA0;
        }

        &:focus {
          box-shadow: 0px 0px 4px 2px #8EA4C5;
        }

      }


    }
  }

  &__tip-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 60px;
    padding-right: 24px;
    margin-top: 17px;
    column-gap: 15px;


    .icon {
      width: 33.33px;
      height:  33.33px;
    }

    .info {
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;


      a {

        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #4D7DA0;
      }
    }
  }
}

.bottom {
  background: rgba(0, 69, 119, 0.9);
  z-index: 1;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  bottom:0;
  left:0;
  //height: 60px;
  width: 100%;
  //overflow: hidden;

  transition: transform 0.3s ease-out;

  .bottombutton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;      
    text-transform: uppercase;

    color: #FFFFFF;


    &:hover {
      background-color: inherit;
    }
  }

  .bottomseparator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    color: #FFFFFF;
  }



}

.jitsi-panel {
  overflow: hidden;
  width: 300px;


  &__header {
    height: 100px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: #651fff;

    img {
      height: 80px;
      // width: 180px;
    }
  }

  &__items {
    border-top: 1px solid #CCCCCC;
    height: calc(100vh - 100px - 120px - 48px);
    overflow-y: auto;
    overflow-x: hidden;

    &_item {
      min-height: 80px;
      border-bottom: 1px solid #CCCCCC;
      display: flex;
      position: relative;

      padding: 16px 14px 16px 10px;

      align-items: flex-start;
      //justify-content: space-between;

      column-gap: 10px;

      transition: background 1s;

      &:nth-child(odd) {
        //background-color: #f6f6f6;
      }

      &--highlight {
        background: rgba(226, 6, 19, 0.15);
      }

      &--moderator {
        flex-direction: column;
        row-gap: 10px;
        padding-right: 14px;
      }

      .icon {
        width: 34px;
        height:  34px;
      }

      .delete-button {
        width:  20px;
        height: 20px;
        position: absolute;
        right: 10px;
        top: 6px;

        img {
          width: 20px;
          height: 20px;

          &:hover {
            filter: hue-rotate(120deg);
          }
        }


      }

      .timestamp {
        position: absolute;
        bottom: 3px;
        right: 14px;

        color: #888;
        font-family: 'Open Sans';
        font-size: 10px;
        text-align: right;
        font-style: normal;
        font-weight: 400;
        line-height: 17px; /* 170% */
        letter-spacing: 0.5px;
      }

      .content {
        color: #000;
        font-family: 'Open Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px; /* 130.769% */
        letter-spacing: 0.5px;
        margin-bottom: 0;
        overflow-wrap: anywhere;
        min-width: 0;
      }
    }


  }

  &__faqs {
    border-top: 1px silver solid;
    display: flex;
    height: 60px;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .text {
      color: #004577;
      text-align: center;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.1px;
    }
  }

  &__bottom {
    position: relative;
    height: 60px;

    .bottom {
      position: absolute;
    }

    .impressumContentContainer {
      padding: 0 8px 0 8px;

      .col1 {
        margin: 0;
        min-width: auto;
      }
    }

  }

}

.moderator-free-text-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 5px;
}

.farewell-page {
  display: flex;

  .shadow-box {
    width: 12px;
    background: linear-gradient(90deg, #D9D9D9 0%, rgba(217, 217, 217, 0.00) 78.13%);
  }

  .farewell-message {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
  
    .text-container {
      width: 65%;
      height: 60%;
  
      .text-alighn {
        margin-top: 150px;
        margin-left: 160px;
      }
    }
  
    .title {
      color: #004577;
      font-family: Open Sans;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  
    p {
      color: #000;
      font-family: Open Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  
    a {
      color: #E20613;
      font-family: Open Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-decoration: none;
    }
  }
}

.header-bottom {
  height: 12px;
  background: linear-gradient(0deg, rgba(217,217,217,0) 0%, rgba(217,217,217,1) 100%);
}

.authBtn {
  display: flex;
  flex-direction: column;
  span {
    margin-top: 6px;
  }
}
.faqsBtn {
  display: flex;
  flex-direction: column;
  span {
    margin-top: 6px;
  }
}

.faqs {
  .title {
    padding:20px 60px; 
    margin-bottom : 30px; 
    color : #004577; 
    font-family: Open Sans; 
    font-size: 24px;
    font-style: normal; 
    font-weight: 700;
    line-height: normal;
  }

  .faqs-container {
    display : flex;
    justify-content : center; 
    overflow-y:auto; 
    height:calc(100vh - 415px);
  }

  .sub-text {
    color: #333; 
    font-family: Arial; 
    font-size: 14px;
    font-style: normal; 
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 15px;
  }

  .accordion {
    box-shadow: none;
    border-bottom: 1px solid silver;
    position: static;
  }
  
  .accordion-sammary {
    color: #111314;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding : 18px;
    
    p {
      margin: 0px;
    }
  }

  .accordion-details {
    color: #333 !important;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding : 0px 20px 20px 20px;

    a {
      color : rgb(51,51,51);
    }
  }
}

.faqs-footer {
  margin: 40px 65px;

  .back-button {
    text-transform: none;
    margin-top: 20px;
    display: flex;
    width: 288px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    border: 2px solid rgb(0, 69, 119);
    background: rgb(255, 255, 255);
    color: rgb(0, 69, 119);
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}



.fade-in {
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-out {
  animation: fadeOut 0.3s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    display: none;
  }
}

.button-container {
  position: fixed;
  bottom: 200px;
  left: 15px;
  z-index: 100;
  cursor: pointer;

  Button{
    border-radius: 100px;
  }

  svg {
    height: 40px;
    background: lightgray;
    border-radius: 100px;
  }
}
.button-container.extended div {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 100px;
    background: lightgray;
    span{
      padding-right: 12px;
      line-height: 40px;
    }
}